<template>
  <div class="container-block">
    <subhead
        :required="required"
        :title="title"
        :title-tooltip="titleTooltip"
        :description-tooltip="descriptionTooltip"
        :width-container="widthTooltip"
        :current-indicator="currentIndicator"
        :general-indicator="generalIndicator"
    />
    <div class="container-block__content">
      <div class="container-block__content-slot">
        <slot></slot>
      </div>
      <icon-component
          v-if="removable"
          @click-element="$emit('remove-item')"
          :icon="'/default-icons/close.svg'"
          :clickability="true"
          :color="'var(--colorIconSecondary)'"
          :size="20"
      />
    </div>

    <div class="container-block__notification" v-if="notificationError&&notificationErrorText">{{notificationErrorText}}</div>
  </div>
</template>

<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import Subhead from "@/components/redesign/Subhead.vue";

/**
 * @prop {String} notificationErrorText - Содержит текст ошибки, которые располагается снизу компонента
 * @prop {Boolean} notificationError - Индикатор ошибки, по дефолту отключён.
 * @prop {Boolean} removable - Отвечает за добавление икоки крестика справа от контентной части формы с событием remove-item
 * @prop {Boolean} required - Отвечает за обязательность формы (добавляет в заголовок символ звёздочки)
 * @prop {String} title - Заголовок
 * @prop {String} titleTooltip - содержит заголовок подсказки
 * @prop {String} descriptionTooltip - содержит подзаголовок подсказки
 * @prop {Number} widthTooltip - даёт возможность жёстко задать ширину подсказки, по дефолту ширина зависит от контента
 * @prop {Number} currentIndicator - текущее значение счётчика
 * @prop {Number} generalIndicator - общее значение счётчика. В заголовке появляется счётчик: currentIndicator/generalIndicator
 */

export default {
  name: "FormItem",
  components: {Subhead, IconComponent},
  props:{
    notificationErrorText:{
      type:String,
      default:'',
    },
    notificationError:{
      type:Boolean,
      default:false,
    },
    removable: {
      type: Boolean,
      default: false
    },
    required:{
      type:Boolean,
      default:false,
    },
    title:{
      type:String,
      default:'',
    },
    titleTooltip: {
      type: String,
      default:''
    },
    descriptionTooltip: {
      type: String,
      default:''
    },
    widthTooltip: {
      type: Number
    },
    currentIndicator:{
      type: Number,
      default:0
    },
    generalIndicator:{
      type: Number,
      default:0
    },
  },
  methods:{},
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.container-block{
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px 0;
  width: 100%;
  .container-block__content {
    display: flex;
    align-items: center;
    gap: 12px;
    .container-block__content-slot {
      width: 100%;
    }
  }
  .container-block__notification{
    color: var(--colorTextNegative);
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
    letter-spacing: 0.012px;
  }
}
</style>