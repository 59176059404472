<script>
export default {
  name: "StockLayout"
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>