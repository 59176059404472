import { render, staticRenderFns } from "./StockLayout.vue?vue&type=template&id=795c2392&scoped=true"
import script from "./StockLayout.vue?vue&type=script&lang=js"
export * from "./StockLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795c2392",
  null
  
)

export default component.exports