import { render, staticRenderFns } from "./IconsLibraryDropdown.vue?vue&type=template&id=71252856&scoped=true"
import script from "./IconsLibraryDropdown.vue?vue&type=script&lang=js"
export * from "./IconsLibraryDropdown.vue?vue&type=script&lang=js"
import style0 from "./IconsLibraryDropdown.vue?vue&type=style&index=0&id=71252856&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71252856",
  null
  
)

export default component.exports