<template>
  <div class="icons-library-dropdown" :class="{'error-wrapper':notificationError, 'only-mobile-view':onlyMobileView}">
    <div
        class="icons-library-dropdown__select"
        :class="{'active':show}"
        @click="changeVisible"
    >
      <template  v-if="value">
        <icon-component
            :icon="`/default-icons/${value}.svg`"
            :color="'var(--colorIconAccentExtra)'"
            :size="20"
        />
        <div class="icons-selected-title">
          <span class="selected-value" v-if="value">{{value}}</span>
        </div>
        <div class="visible-change-list" :class="{'active':show}">
          <icon-component
              :icon="'/default-icons/expand_more.svg'"
              :color="'var(--colorIconSecondary)'"
              :size="20"
          />
        </div>
      </template>
    </div>
    <div class="icons-options-wrapper" v-if="show" v-on-clickaway="cancelChanges">
      <div class="icons-options" @mousedown.self="cancelChanges" @touchstart.self="cancelChanges">
        <div class="icons-options__container">
          <div class="wrapper-list-icons">
            <div class="container-list-icons">
              <template v-for="element in sortedList">
                <div
                    class="options-icon"
                    :class="{'active':temporaryIcon===element.id}"
                    @click.stop="selectValues(element.id)"
                    :key="element.id"
                >
                  <icon-component
                      v-if="element.path"
                      :icon="element.path"
                      :clickability="false"
                      :color="'var(--colorIconPrimary)'"
                      :size="20"
                  />
                </div>
              </template>
            </div>
          </div>
          <div class="icons-footer-list">
            <default-button size="medium" type="secondary" @click-element="cancelChanges">{{$t('Отменить')}}</default-button>
            <default-button size="medium" @click-element="applyChanges">{{$t('Применить')}}</default-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import IconComponent from "@/components/redesign/iconComponent.vue";
import naturalSort from "@/mixins/naturalSort";
import DefaultButton from "@/components/redesign/defaultButton.vue";
export default {
  name: "IconsLibraryDropdown",
  components: {DefaultButton, IconComponent},
  mixins:[naturalSort],
  props:  {
    value:{

    },
    notificationError:{},
    onlyMobileView:{
      type:Boolean,
      default:false
    },
  },
  data () {
    return {
      show: false,
      temporaryIcon:'',
      iconsPack:[
        '360', '3d_layout_object', 'account_circle', 'add_circle', 'attach_file', 'block', 'bus-station', 'calculate', 'calendar', 'call',
        'chat_bubble', 'check_circle', 'coffee', 'coin', 'collection', 'dark_mode', 'deal', 'delete', 'favorite', 'flag',
        'folder', 'forest', 'east', 'edit', 'groups', 'home', 'keep', 'label', 'landscape', 'language',
        'layout_project', 'light_mode', 'like', 'location_on', 'lock', 'mic', 'mention', 'north', 'park', 'pdf',
        'profile', 'restaurant', 'promo', 'roller', 'school', 'security', 'settings', 'shopping-basket', 'south', 'stories_image',
        'stories_video', 'support', 'timer', 'visibility', 'west', 'work'
      ]
    }
  },
  mounted () {
    this.initialization()
  },
  methods: {
    cancelChanges(){
      this.temporaryIcon=this.value
      this.show=false
    },
    initialization(){
      if(!this.value)this.$emit('input', 'add_circle')
      this.temporaryIcon=this.value
    },
    changeVisible(){
      this.show=!this.show
    },

    selectValues (id) {
      this.temporaryIcon=id
    },
    applyChanges(){
      this.$emit('input', this.temporaryIcon)
      this.show=false
    },
  },
  computed: {
    sortedList (){
      const sortPack = this.naturalSort(this.iconsPack)
      return sortPack.map(icon=>({id:icon,path:`/default-icons/${icon}.svg`}))
    },
  },
  watch:{
    value(){
      this.initialization()
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes show-modal {
  0%{
    transform: translate3d(0px, 100%, 0px);
  }
  100%{
    transform: translate3d(0px, 0, 0px);
  }
}
@keyframes show-back {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
@mixin mobileView(){
  .icons-library-dropdown__select{
    padding: 14px 16px;
    .icons-library-dropdown__select_title{
      font-size: 16px;
      line-height: 20px; /* 128.571% */
    }
  }
}
.icons-library-dropdown{
  min-width: 150px;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  &.error-wrapper{
    .icons-library-dropdown__select{
      border: 0.5px solid var(--colorStrokeNegative);
      background: var(--colorBackgroundNegativeTint);
      &:hover{
        background: var(--colorBackgroundNegativeTintHover);
      }
      &.active{
        border: 0.5px solid var(--colorStrokeNegative);
        background: var(--colorComponentFieldBackgroundActive);
        &:hover{
          background: var(--colorComponentFieldBackgroundActive);
        }
      }
    }
  }
  &.only-mobile-view{
    @include mobileView;
  }
  .icons-library-dropdown__select{
    width: 100%;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    background-color: var(--colorComponentFieldBackground);
    border: 0.5px solid var(--colorComponentFieldStroke);
    transition: background 0.3s, border 0.3s;
    &:hover{
      background-color: var(--colorComponentFieldBackgroundHover);
    }
    &.active{
      border: 0.5px solid var(--colorStrokeAccentExtraInvariably);
      background: var(--colorComponentFieldBackgroundActive);
    }
    .icons-selected-title{
      overflow: hidden;
      color: var(--colorTextSecondary);
      font-variant-numeric: lining-nums tabular-nums;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
      letter-spacing: 0.035px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      .selected-value{
        color: var(--colorTextPrimary);
      }
    }
    .visible-change-list{
      transition: all 0.3s;
      transform: rotate(0);
      &.active{
        transform: rotate(-180deg);
      }
    }
  }
  .icons-options-wrapper{
    position: absolute;
    z-index: 1000;
    top:calc(100% + 8px);
    left: 0;
    width: 100%;
    min-width: min-content;
    .icons-options{
      .icons-options__container{
        border-radius: 12px;
        border: 0.5px solid var(--colorStrokeSecondary);
        background-color: var(--colorBackgroundContent);
        /* Light/Elevation M */
        box-shadow: 0px 8px 16px 0px rgba(23, 23, 28, 0.05), 0px 0px 8px 0px rgba(30, 30, 36, 0.10);
        text-transform: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .wrapper-list-icons{
          .container-list-icons{
            padding: 16px;
            height: max-content;
            max-height: 200px;
            overflow-y: auto;
            overflow-x:hidden;

            display: flex;
            align-items: flex-start;
            align-content: flex-start;
            gap: 8px;
            flex-wrap: wrap;
            justify-content: center;
            &::-webkit-scrollbar{
              width: 5px;
            }
            &::-webkit-scrollbar-track{
              background: transparent;
            }
            &::-webkit-scrollbar-thumb{
              border-radius: 8px;
              background: var(--colorComponentScrollbarKnob);
            }
            .options-icon{
              /* padding: 7px 0;*/
              aspect-ratio: 1 / 1;
              width: 100%;
              max-width: 36px;
              border-radius: 8px;
              border: 1px solid var(--colorStrokeSecondary);
              background-color: var(--colorBackgroundContent);
              transition: all 0.2s ease;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover{
                background-color: var(--colorBackgroundSecondary);
              }
              &.active{
                border: 1px solid var(--colorStrokeAccentExtra);
              }
            }
          }
        }
        .icons-footer-list{
          border-top: 1px solid var(--colorStrokeSecondary);
          background: var(--colorBackgroundContent);

          display: flex;
          gap: 8px;
          padding: 8px 16px;
        }
      }
    }
  }
}
@media screen and (max-width: 1365px){
  .icons-library-dropdown{
    @include mobileView;
  }
}
@media screen and (max-width: 743px){
  .icons-library-dropdown{
    .icons-options-wrapper{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3500;
      background: var(--colorOverlay);

      animation-name:show-back;
      animation-duration: 0.5s;
      .icons-options{
        padding: 16px;
        height: 100%;
        width: 100%;
        display: flex;
        overflow: auto;

        animation-name:show-modal ;
        animation-duration: 0.5s;
        .icons-options__container{
          margin: auto auto 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
