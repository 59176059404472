import { render, staticRenderFns } from "./ProjectsLayout.vue?vue&type=template&id=dcc38ece&scoped=true"
import script from "./ProjectsLayout.vue?vue&type=script&lang=js"
export * from "./ProjectsLayout.vue?vue&type=script&lang=js"
import style0 from "./ProjectsLayout.vue?vue&type=style&index=0&id=dcc38ece&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc38ece",
  null
  
)

export default component.exports